import React from "react";
import "./dealer-page.scss";
import { FaArrowRight, FaPhone } from "react-icons/fa6";
export default function DealerPage() {
  return <div>
    {/* HERO */}
    <div className="d-flex align-items-stretch">
      <div className="w-100 d-flex align-items-center justify-content-center">
        <div className="text-white mb-5">
          <h1 className="my-0 fs-md-55">Our Selected Dealers</h1>
          <p className="text-center fs-md-20">...</p>
        </div>
      </div>
    </div>

    <div className="px-2 px-md-4 py-4 py-md-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <div className="text-decoration-none text-white rounded-3 overflow-hidden h-100 d-flex flex-column" style={{ minHeight: 250 }}>
              <div className="flex-fill d-flex align-items-start justify-content-center flex-column bg-dark rounded-3 overflow-hidden p-3">
                <p className="my-0">Dealer Address 1</p>
                <p className="my-0">Dealer Address 2</p>
                <p className="my-0">Dealer Address 3 / POSTCODE</p>
                <div className="d-flex align-items-center justify-content-between gap-2 mt-3">
                  <FaPhone className="lh-1" />
                  <a href="tel:" className="my-0 text-decoration-none text-white">Dealer Phone Number</a>
                </div>
              </div>
              <a href="/" className="d-flex align-items-center justify-content-between gap-2 text-decoration-none">
                <span className="text-white text-decoration-none fs-md-20 my-2">Dealer Name</span>

                <div className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <FaArrowRight className="lh-1" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}


