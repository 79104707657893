import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return <div className="bg-dark px-0 text-white">
    <div className="container">
      <div className="p-0 row">
        {/* <div className="col-12 col-md-4">
          <h4>Support</h4>
          <Link className="d-block py-2" to={'/pages/privacy-policy'}>Privacy</Link>
          <Link className="d-block py-2" to={'/pages/terms-and-conditions'}>Terms And Conditions</Link>
          <Link className="d-block py-2" to={'/pages/faqs'}>FAQ's</Link>
        </div> */}
        {/* <div className="col-12 col-md-4">
          <h4>Contact</h4>
        </div> */}
        {/* <div className="col-12 col-md-4">
          <h4>Support</h4>
          <p>Subscribe to our newsletter for product updates, new arrivals and stock notifications</p>
          <div id="mdp_fa9af473-068f-4551-9c0f-11d487f0af20"></div>
        </div> */}
      </div>
      <div className="row border-top border-dark pt-4 pb-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <span className="d-block">©2024 by Adore Gas</span>
          {/* <a className="text-center flex-fill d-block text-decoration-underline text-white" href="/dealers">Purchase online through your preferred dealer</a> */}
          <Link to="https://www.lukestonier.com" className="d-flex align-items-center justify-content-end text-decoration-none">
            <span className="ms-1 d-block text-white">Site by Luke Stonier</span>
          </Link>
        </div>
      </div>
    </div>
  </div>;
}


