import React, { useCallback, useRef, useState } from "react";
import "./register-product.scss";
import StatefullInput from "../../components/statefull-input/statefull-input";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import HttpService, { HttpError } from "../../services/http-service";
import { FaCircleCheck } from "react-icons/fa6";

export class WarrantyEntity {
  public forename: string;
  public surname: string;
  public email: string;
  public addressline1: string;
  public zip: string;
  public tel: string;
  public purchase_date: Date;
  public model_no: string;
  public serial_no: string;
  public installer_name: string;
  public gas_safe_no: string;
  public installers_tel: string;

  public tags: string;

  // public lastService: Date;
  // public retail_store: string;
  // public retailer_zip: string;
}

export default function RegisterProduct() {
  const [warranty, setWarranty] = useState<WarrantyEntity>(new WarrantyEntity());
  const [stateChangeEvent] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
  const [complete, setComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const updateQueue = useRef<{ field: keyof WarrantyEntity; value: string | Date }[]>([]);
  const isProcessing = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processQueue = () => {
    if (isProcessing.current || updateQueue.current.length === 0) return;

    isProcessing.current = true;

    const { field, value } = updateQueue.current.shift()!;

    setWarranty((prevState) => {
      const updatedState = { ...prevState, [field]: value };
      return updatedState;
    });

    // Wait for React to apply the state update before processing the next item
    setTimeout(() => {
      isProcessing.current = false;
      processQueue();
    }, 0);
  };

  const HandleInputChange = useCallback((field: keyof WarrantyEntity, value: string | Date) => {
    updateQueue.current.push({ field, value });
    processQueue()
  }, [processQueue])

  const SubmitWarranty = useCallback(() => {
    setLoading(true);
    setComplete(false);
    var valid = true;
    const errors: IErrorFix[] = [];
    warranty.tags = 'adore_gas_entry';
    Object.keys(warranty).forEach((key: string) => {
      if (key === 'purchase_date') {
        if (warranty.purchase_date <= new Date(0)) {
          errors.push({ key: key, value: 'Invalid Date' })
          valid = false;
        }
      }
      if ((warranty as any)[key] === undefined || (warranty as any)[key] === null || (warranty as any)[key] === '') {
        errors.push({ key: key, value: 'Can not be empty' })
        valid = false;
      }
    })

    if (!valid) {
      setTimeout(() => {
        setLoading(false);
        console.log(errors);
        stateChangeEvent.emit({ error: '', fixes: errors })
      }, 500);
      return;
    }

    const body = {
      ...warranty,
      tags: 'adore_gas_entry',
      purchase_date: warranty.purchase_date.toLocaleString().substring(0, 10)
    };
    console.log(body);
    var url = `https://hpl-support-api-f9ef272ba17c.herokuapp.com/api/warranty/add/new`;
    HttpService.post(url, body, (_) => {
      setLoading(false);
      setComplete(true);
      setWarranty(new WarrantyEntity());
      alert("Warranty Registered");
    }, (err: HttpError) => {
      setComplete(false);
      setLoading(false);
      console.log(err);
      alert(err.error.error);
    })


  }, [warranty, stateChangeEvent])

  return <div className="w-75 mx-auto pt-5" style={{ maxWidth: 600 }}>
    <div>
      <h2 className="text-white">Register A New Product</h2>
      <p className="text-white">Complete the following form and press submit to register your new appliance.</p>
    </div>
    {complete ? <>
      <FaCircleCheck className="display-1 mx-auto d-block text-success text-center mt-5 mb-2" />
      <p className="text-white text-center">Thank you for registering your appliance!</p>
    </> :
      <div className="container-fluid bg-dark p-4 rounded-3 shadow">
        <div className="row">
          <StatefullInput stateId={"forename"} label={"Forename"} defaultValue={warranty.forename} inputType={"text"} autocompleteType={"given-name"} onChangeCallback={(_: string) => {
            HandleInputChange("forename", _);
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"surname"} label={"Surname"} defaultValue={warranty.surname} inputType={"text"} autocompleteType={"family-name"} onChangeCallback={(_: string) => {
            HandleInputChange("surname", _);
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <StatefullInput stateId={"email"} label={"Email"} defaultValue={warranty.email} inputType={"email"} autocompleteType={"email"} onChangeCallback={(_: string) => {
            HandleInputChange("email", _);
          }} classes="text-white col-12 col-md-12" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <StatefullInput stateId={"tel"} label={"Phone Number"} defaultValue={warranty.tel} inputType={"tel"} autocompleteType={"tel"} onChangeCallback={(_: string) => {
            HandleInputChange("tel", _);
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"purchase_date"} label={"Purchase Date"} defaultValue={new Date(0)} inputType={"date"} autocompleteType={""} onChangeCallback={(_: string) => {
            HandleInputChange("purchase_date", new Date(_));
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <StatefullInput stateId={"addressline1"} label={"Address Line 1"} defaultValue={warranty.addressline1} inputType={"text"} autocompleteType={"address-line1"} onChangeCallback={(_: string) => {
            HandleInputChange("addressline1", _);
          }} classes="text-white col-12 col-md-9" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"zip"} label={"Post Code"} defaultValue={warranty.zip} inputType={"text"} autocompleteType={"postal-code"} onChangeCallback={(_: string) => {
            HandleInputChange("zip", _);
          }} classes="text-white col-12 col-md-3" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <StatefullInput stateId={"model_no"} label={"Model Number"} defaultValue={warranty.model_no} inputType={"text"} autocompleteType={"text"} onChangeCallback={(_: string) => {
            HandleInputChange("model_no", _);
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"serial_no"} label={"Serial Number"} defaultValue={warranty.serial_no} inputType={"text"} autocompleteType={"text"} onChangeCallback={(_: string) => {
            HandleInputChange("serial_no", _);
          }} classes="text-white col-12 col-md-6" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <StatefullInput stateId={"installer_name"} label={"Installer Name"} defaultValue={warranty.installer_name} inputType={"text"} autocompleteType={"text"} onChangeCallback={(_: string) => {
            HandleInputChange("installer_name", _);
          }} classes="text-white col-12 col-md-4" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"installers_tel"} label={"Installer Phone Number"} defaultValue={warranty.installers_tel} inputType={"text"} autocompleteType={"text"} onChangeCallback={(_: string) => {
            HandleInputChange("installers_tel", _);
          }} classes="text-white col-12 col-md-4" stateChangeEvent={stateChangeEvent} />
          <StatefullInput stateId={"installers_tel"} label={"Gas Safe Number"} defaultValue={warranty.gas_safe_no} inputType={"text"} autocompleteType={"text"} onChangeCallback={(_: string) => {
            HandleInputChange("gas_safe_no", _);
          }} classes="text-white col-12 col-md-4" stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <button disabled={loading} className="w-100 btn btn-danger" onClick={() => SubmitWarranty()}>{loading ? 'LOADING...' : 'SUBMIT'}</button>
          </div>
        </div>
      </div>
    }
  </div>;
}


