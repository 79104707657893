import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LandingPage from './pages/landing-page/landing-page';
import Theme from './misc/theme';
import DealerPage from './pages/dealer-page/dealer-page';
import RegisterProduct from './pages/register-product/register-product';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />

          <Route path="/" element={<Theme />}>
            <Route index element={<LandingPage />}></Route>

            <Route path='/dealers' element={<DealerPage />}></Route>
            <Route path='/register-new-product' element={<RegisterProduct />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
