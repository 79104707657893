import React from "react";
import "./landing-page.scss";
import { FaArrowRight } from "react-icons/fa6";
export default function LandingPage() {
  return <div>
    {/* HERO */}
    <div className="d-flex align-items-stretch" style={{ backgroundImage: "url('https://d3vcct82n0rrtb.cloudfront.net/site-media/adore_bg_new.png')", backgroundPosition: 'center 60%', backgroundSize: 'cover', minHeight: '50vh' }}>
      <div className="w-100 d-flex align-items-center justify-content-center" style={{ background: 'rgba(0,0,0,0.75)' }}>
        <div className="text-white mb-5">
          <img src="https://d3vcct82n0rrtb.cloudfront.net/site-media/adore_logo.png" height="150" className="d-block" alt="logo" />
        </div>
      </div>
    </div>

    {/* Products */}
    <div className="px-2 px-md-4 py-4 py-md-5">
      <div className="container-fluid">
        <div className="row">
          <p className="text-white fs-md-35 fs-mb-30">Products</p>
        </div>
        <div className="row">

          {/*  */}
          <div className="col-12 col-md-4">
            <div className="rounded-3 overflow-hidden">
              <div className="">
                <img alt="" src="https://d3vcct82n0rrtb.cloudfront.net/site-media/FO1 Brass and Black 45.jpg" className="d-block w-100" />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p className="text-white fs-md-20 my-2">Brass & Black</p>

                <a href="/f01-brass-and-black" className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <span className="">View Details</span>
                  <FaArrowRight className="lh-1" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="rounded-3 overflow-hidden">
              <div className="">
                <img alt="" src="https://d3vcct82n0rrtb.cloudfront.net/site-media/FO2 Nickle and Black 45.jpg" className="d-block w-100" />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p className="text-white fs-md-20 my-2">Nickle & Black</p>

                <a href="/f02-nickle-and-black" className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <span className="">View Details</span>
                  <FaArrowRight className="lh-1" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="rounded-3 overflow-hidden">
              <div className="">
                <img alt="" src="https://d3vcct82n0rrtb.cloudfront.net/site-media/FO3 Chrome and Black 45.jpg" className="d-block w-100" />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p className="text-white fs-md-20 my-2">Chrome & Black</p>

                <a href="/f03-chrome-and-black" className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <span className="">View Details</span>
                  <FaArrowRight className="lh-1" />
                </a>
              </div>
            </div>
          </div>


        </div>

        <div className="row mt-2 mt-md-5">

          {/*  */}
          <div className="col-12 col-md-4">
            <div className="rounded-3 overflow-hidden">
              <div className="">
                <img alt="" src="https://d3vcct82n0rrtb.cloudfront.net/site-media/FO4 45 set.jpg" className="d-block w-100" />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p className="text-white fs-md-20 my-2">F04 Set</p>

                <a href="/f04-set" className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <span className="">View Details</span>
                  <FaArrowRight className="lh-1" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="rounded-3 overflow-hidden">
              <div className="">
                <img alt="" src="https://d3vcct82n0rrtb.cloudfront.net/site-media/FO5 Black Nickle and Black 45.jpg" className="d-block w-100" />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p className="text-white fs-md-20 my-2">Black Nickle & Black</p>

                <a href="/f05-black-nickle-and-black" className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <span className="">View Details</span>
                  <FaArrowRight className="lh-1" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <a href="/" className="text-decoration-none text-white rounded-3 overflow-hidden h-100 d-flex flex-column" style={{ minHeight: 250 }}>
              <div className="flex-fill d-flex align-items-center justify-content-center bg-dark rounded-3 overflow-hidden">
                <h4 className="text-white text-center my-0 fs-md-30 fs-mb-25">Available now through<br />our selected dealers </h4>
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2 text-decoration-none">
                <span className="text-white text-decoration-none fs-md-20 my-2">See More</span>

                <div className="text-white text-decoration-none d-flex align-items-center justify-content-end gap-2">
                  <FaArrowRight className="lh-1" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div >;
}


