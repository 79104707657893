import React, { useCallback, useEffect, useState } from "react";
import EventEmitter from "../../services/event/event-emitter";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";

export default function StatefullInput({
	stateId,
	label,
	placeholder = "",
	defaultValue,
	index,
	inputType,
	autocompleteType,
	onChangeCallback,
	onPressCallback,
	onPressWithChange,
	stateChangeEvent,
	classes,
	resetEvent = new EventEmitter<void>(),
	dirtyEvent = new EventEmitter<void>(),
	externalUpdate = new EventEmitter<string>(),
	required = false,
	maxLength,
	options,
	readOnly
}: {
	stateId: string;
	label: string;
	placeholder?: string;
	defaultValue: string | Date;
	index?: number;
	inputType: string;
	autocompleteType: string;
	onChangeCallback: (_: string) => void;
	onPressCallback?: (_: any) => void;
	onPressWithChange?: (_: any, change: EventEmitter<string>) => void;
	stateChangeEvent?: EventEmitter<IErrorResp>;
	classes?: string;
	resetEvent?: EventEmitter<void>;
	dirtyEvent?: EventEmitter<void>;
	externalUpdate?: EventEmitter<string>;
	required?: boolean;
	maxLength?: number;
	options?: { value: string, view: string }[]
	readOnly?: boolean
}) {
	const [value, setValue] = useState(defaultValue || "");
	const [forceError, setForceError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	const validate = useCallback(
		(errorResp: IErrorResp) => {
			setForceError(false);
			setIsDirty(true);
			setErrorMessage("");
			if (errorResp !== null && errorResp.fixes != null) {
				errorResp.fixes.forEach((fix: IErrorFix) => {
					if (fix.key === stateId) {
						setErrorMessage(fix.value);
					}

					if (fix.key === "global") setForceError(true);
				});
			}
		},
		[stateId]
	);

	useEffect(() => {
		onChangeCallback(value.toString());
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		externalUpdate.subscribe((_: string) => {
			setValue(_);
		});
		resetEvent.subscribe(() => {
			setValue(defaultValue);
		});
		if (stateChangeEvent !== null && stateChangeEvent !== undefined) {
			const stateSubscription = stateChangeEvent.subscribe((_: IErrorResp) => {
				validate(_);
			});

			return () => {
				stateChangeEvent.unsubscribe(stateSubscription);
			};
		}
	}, [stateId, stateChangeEvent, validate, defaultValue, resetEvent, externalUpdate]);

	const validStateClass = () => {
		if (forceError) return "is-invalid";
		if (!isDirty) return "";
		if (isDirty && errorMessage === "") return "is-valid";
		return "is-invalid";
	};

	const errorElement = () => {
		return errorMessage ? <label className="form-label text-danger small">{errorMessage}</label> : null;
	};

	return (
		<div className={classes}>
			{label == null ? null : (
				<label htmlFor={`${stateId}Input`} className="form-label mb-0">
					{label}
				</label>
			)}
			{inputType === 'textarea' ?
				<textarea
					readOnly={readOnly}
					required={required}
					placeholder={placeholder}
					autoComplete={autocompleteType}
					id={`${stateId}Input`}
					className={`form-control ${validStateClass()}`}
					value={value.toString()}
					style={{ resize: 'vertical', minHeight: 100, maxHeight: 500 }}
					maxLength={maxLength === undefined ? undefined : maxLength}
					onChange={(event) => {
						setValue(event.target.value);
					}}
					onKeyDown={(e: any) => {
						if (onPressCallback !== undefined) onPressCallback(e);
						if (onPressWithChange !== undefined) onPressWithChange(e, externalUpdate);
					}}
					onClick={() => {
						dirtyEvent.emit();
					}}
				/>
				: inputType === 'select' ?
					<select
						disabled={readOnly}
						required={required}
						autoComplete={autocompleteType}
						id={`${stateId}Input`}
						className={`form-select ${validStateClass()}`}
						defaultValue={defaultValue.toString()}
						onChange={(event) => {
							setValue(event.target.value);
						}}
						onClick={() => {
							dirtyEvent.emit();
						}}
					>
						{options?.map((o: { value: string, view: string }, index: number) => {
							return <option key={index} value={o.value}>{o.view}</option>
						})}
					</select>
					:
					<input
						readOnly={readOnly}
						required={required}
						placeholder={placeholder}
						autoComplete={autocompleteType}
						id={`${stateId}Input`}
						type={inputType}
						className={`form-control ${validStateClass()}`}
						value={value.toString()}
						maxLength={maxLength === undefined ? undefined : maxLength}
						onInput={(event) => {
							//console.log('oi', event.currentTarget.value);
							setValue(event.currentTarget.value);
						}}
						onInputCapture={(event) => {
							//console.log('oic', event.currentTarget.value);
							setValue(event.currentTarget.value);
						}}
						onChange={(event) => {
							setValue(event.target.value);
						}}
						onKeyDown={(e: any) => {
							if (onPressCallback !== undefined) onPressCallback(e);
							if (onPressWithChange !== undefined) onPressWithChange(e, externalUpdate);
						}}
						onClick={() => {
							dirtyEvent.emit();
						}}
					/>
			}
			{errorElement()}
		</div>
	);
}
