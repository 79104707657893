import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavHeader from "./nav-header";
import Footer from "./footer";

export default function Theme() {
    // const navigate = useNavigate();

    try {
        const location = useLocation();

        const routeThemes: { path: string; colour: string }[] = [
            { path: "/", colour: "#F8FAFB" },
            // { path: "/login", colour: "#F8FAFB" },
            // { path: "/register", colour: "#F8FAFB" },
            // { path: "/recovery", colour: "#F8FAFB" },
        ];

        var metaTag = document.querySelector('meta[name="theme-color"]');
        let themeColour = "#FFF";

        routeThemes.forEach((_: { path: string; colour: string }) => {
            if (_.path === location.pathname) themeColour = _.colour;
        });

        if (metaTag != null) metaTag.setAttribute("content", themeColour);
    } catch (exception: any) {
        console.warn(exception);
    }

    const idevice =
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    return (
        <div className="" style={{ background: '#000' }}>
            <NavHeader />
            <div className={`d-flex align-items-stretch flex-column h-100 w-100 ${idevice ? "i_device" : ""}`} style={{ 'minHeight': '100lvh', background: '#000' }}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}
