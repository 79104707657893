import React from "react";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa6';

export default function NavHeader() {

  return <>
    <div className="container-fluid px-0">
      {/* <div className="py-2 px-3 px-md-0 text-white bg-dark">
        <div className="container d-flex align-items-center justify-content-end gap-3 pe-0">
          <a href="tel:01785719005" className="text-white">01785 719005</a>
        </div>
      </div> */}
      <div className="py-2 container">
        <div className="row px-3 px-sm-0">

          <Link to={"/"} className="col-10 col-md-4 col-xl-2 d-flex align-items-center justify-content-between ps-0">
            <img src="https://d3vcct82n0rrtb.cloudfront.net/site-media/logo.png" height="70" className="d-inline-block align-top" alt="logo" />
          </Link>


          <div className="col-11 col-md-7 col-xl-10 d-none d-md-flex align-items-center justify-content-between pe-0 ps-0 position-relative">
            {/* <div>
              <Link className="text-white text-decoration-none border border-1 border-white px-3 py-2 rounded-2" to={"/collections/electric-fireplace-suites"}>Electric Fireplace Suites</Link>
            </div> */}

            <Link to={"/register-new-product"} className="d-none d-xl-block text-white text-decoration-none">
              <p className="text-white my-0">Register A Product</p>
            </Link>
          </div>

          <div className="d-flex align-items-center justify-content-end d-xl-none col-md-1 col-2 pe-0">
            <div className="position-relative">
              <FaBars className="fs-2 text-white" data-bs-toggle="offcanvas" data-bs-target="#sidenav" aria-controls="sidenav" style={{ cursor: 'pointer' }} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-end" tabIndex={-1} id="sidenav" aria-labelledby="sidenavLabel">
      <div className="offcanvas-header bg-dark">
        <h3 id="sidenavLabel" className="text-white my-0">Adore Gas</h3>
        <button type="button" className="btn-close text-white" style={{ color: '#fff' }} data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body bg-dark">
        <div className="d-flex flex-column align-items-start justify-content-start gap-1">
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/"} className="text-white d-block">Home</Link>
          <Link data-bs-dismiss="offcanvas" to={"/register-new-product"} className="text-white d-block">Register A Product</Link>
        </div>
      </div>
    </div>
  </>;
}


